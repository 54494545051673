<template>
    <div class="pointRule_detail">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="120px" size="small">
                <el-form-item label="企业名称">
                    <el-input v-model="form.companyName" readonly />
                </el-form-item>
                <el-form-item label="机构组名称">
                    <el-input v-model="form.deptGroupName" readonly />
                </el-form-item>
                <el-form-item label="消费金额(元)">
                    <el-input :value="(form.money / 10000).toFixed(2)" readonly />
                </el-form-item>
                <el-form-item label="积分">
                    <el-input v-model="form.point" readonly />
                </el-form-item>
                <el-form-item label="清零日期(月-日)">
                    <el-input v-model="form.ruleEndTime" readonly />
                </el-form-item>
                <el-col>
                    <el-form-item label="有效期(年)">
                        <el-select v-model="form.effectiveTime">
                            <el-option label="一年" :value="1" />
                            <el-option label="两年" :value="2" />
                            <el-option label="三年" :value="3" />
                            <el-option label="五年" :value="5" />
                            <el-option label="十年" :value="10" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-row />
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'PointRuleDetail',
    props: ['form'],
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
